import React, { useState, useEffect } from 'react';
import { addDoc, deleteDoc, doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { notesCollection, notesCollection2 } from './firebase';
import EntryList from './EntryList'; // Assuming EntryList is a separate component
import CharityDashboard from './CharityDashboard';
import {UserAuth} from './AuthContext'
//import { onAuthStateChanged } from "firebase/auth";
import {query, where } from "firebase/firestore";
//import EntryForm from './EntryForm';
//import Home from './Home.js'
import CryptoJS from "crypto-js";





function Journal() {
    const {logOut,user}=UserAuth();
    console.log(user);
    const [groupedEntries, setGroupedEntries] = useState({});
    const [groupedArchivedEntries, setGroupedArchivedEntries] = useState({});
    //archive button toggle
    const [showArchived, setShowArchived] = useState(false);
    const secretKey = process.env.REACT_APP_SECRET_KEY; // Secret key stored in .env file
        // Utility function to decrypt text
   

    const handleSignOut = async () => {
        try{
          await logOut()
        } catch (error) {
          console.log(error)
        }
        //signOut(auth);
      };
      //const user = { displayName: "Test User" };

    const toggleArchivedNotes = () => {
        setShowArchived(!showArchived);
    };

    // Utility function to group entries by month
    const groupEntriesByMonth = (entries) => {
        // Sort the entries by createdAt in descending order
        entries.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
    
        return entries.reduce((groups, entry) => {
            // Ensure entry has a valid createdAt and it's a valid Firebase Timestamp
            if (entry.createdAt && entry.createdAt.seconds) {
                const date = new Date(entry.createdAt.seconds * 1000); // Convert Firebase Timestamp to JS Date
    
                // Check if the date is valid
                if (isNaN(date.getTime())) {
                    console.warn("Invalid date value found:", entry.createdAt);
                    return groups; // Skip this entry if the date is invalid
                }
    
                // Format the date as "Month Year"
                const month = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(date);
    
                // Group by month
                if (!groups[month]) {
                    groups[month] = [];
                }
                groups[month].push(entry);
            } else {
                console.warn("Missing or invalid createdAt field:", entry);
            }
    
            return groups;
        }, {});
    };
    
    const decryptText = (encryptedText) => {
        try {
        const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
        console.error("Error decrypting text:", error);
        return ""; // Return empty string if decryption fails
        }
    };

    useEffect(() => {
       //if (!user || !user.uid) return; // Prevent running if user is undefined
       if (!user?.uid) return; // Ensure user is authenticated

        const userNotesQuery = query(notesCollection, where("userId", "==", user.uid));
        const unsubscribeEntries = onSnapshot(userNotesQuery, (snapshot) => {
            const notesArr = snapshot.docs.map((doc) => {
                const data = doc.data();
                return {
                ...data,
                text: decryptText(data.text),
                id: doc.id,
                createdAt: data.createdAt instanceof Timestamp ? data.createdAt : Timestamp.fromDate(new Date(data.createdAt)),
                updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt : Timestamp.fromDate(new Date(data.updatedAt)),
                
            };
        });
            //setGroupedEntries(groupEntriesByMonth(notesArr));
            const grouped = groupEntriesByMonth(notesArr);
            setGroupedEntries(grouped);
        });
    
        return () => unsubscribeEntries();
    }, [user]);

    useEffect(() => {
        if (!user?.uid) return; // Ensure user is authenticated

        const userNotesQuery2 = query(notesCollection2, where("userId", "==", user.uid));
        const unsubscribeArchivedEntries = onSnapshot(userNotesQuery2, (snapshot) => {
            const archivedArr = snapshot.docs.map((doc) => {
                const data = doc.data();
                return{
                    ...data,
                    text: decryptText(data.text),
                    id: doc.id,
                    createdAt: data.createdAt instanceof Timestamp ? data.createdAt : Timestamp.fromDate(new Date(data.createdAt)),
                    updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt : Timestamp.fromDate(new Date(data.updatedAt)),

            
                };
            });
            const grouped = groupEntriesByMonth(archivedArr);
            setGroupedArchivedEntries(grouped);
        }
    );

        return () => unsubscribeArchivedEntries();
    }, [user]);

    // Add a new entry to Firestore
    /*const addEntry2 = async (entry) => {
        console.log("Adding note for user ID:", user.uid); // Debugging line
        await addDoc(notesCollection, {
            text: entry.text,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            userId: user.uid, // Store the user ID with the note

        });
    };
    */
    const encryptText = (text) => {
        if (!secretKey) {
            console.error("Encryption key is not defined.");
            return text; // Return the original text if no key is available
        }
      
        try {
            return CryptoJS.AES.encrypt(text, secretKey).toString();
        } catch (error) {
            console.error("Error encrypting text:", error);
            return ""; // Return empty string if encryption fails
        }
      };
      
    // Archive an entry by moving it to the archived collection
    const archiveEntry = async (id) => {
    try {
        const entryToArchive = Object.values(groupedEntries).flat().find(entry => entry.id === id);
        if (entryToArchive) {
            // Encrypt the text before archiving
            const encryptedText = encryptText(entryToArchive.text);

            // Delete from the active collection and add to archived, preserving createdAt
            await deleteDoc(doc(notesCollection, entryToArchive.id));
            await addDoc(notesCollection2, {
                ...entryToArchive, 
                text: encryptedText, // Encrypt the text
                updatedAt: Timestamp.now(), // Only update the updatedAt field
            });
        } else {
            console.error("Entry not found to archive.");
        }
    } catch (error) {
        console.error("Error archiving entry: ", error);
    }
};


/*
const archiveEntry = async (entry) => {
    if (!user?.uid) {
        console.error("User is not authenticated.");
        return;
    }

    const encryptedText = encryptText(entry.text); // Encrypt the text
    console.log("Adding note for user ID:", user.uid); // Debugging line

    try {
        await addDoc(notesCollection, {
            text: encryptedText,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            userId: user.uid, // Store the user ID with the note
        });
        console.log("Note added successfully!");
    } catch (error) {
        console.error("Error adding entry:", error);
    }
};
*/
    // Restore an archived entry back to active entries
    const restoreEntry = async (id) => {
        try {
            const entryToRestore = Object.values(groupedArchivedEntries).flat().find(entry => entry.id === id);
            if (entryToRestore) {
                // Decrypt the text before restoring
                const encryptedText = encryptText(entryToRestore.text);
                // Delete from the archived collection and add back to active, preserving createdAt
                await deleteDoc(doc(notesCollection2, entryToRestore.id));
                await addDoc(notesCollection, {
                    ...entryToRestore, 
                    text: encryptedText, // Decrypt the text
                    updatedAt: Timestamp.now(), // Only update the updatedAt field
                });
            } else {
                console.error("Entry not found to restore.");
            }
        } catch (error) {
            console.error("Error restoring entry: ", error);
        }
    };

    // Delete an entry from either active or archived entries
    const deleteEntry = async (id, archiveMode) => {
        try {
            if (archiveMode) {
                const entryToDelete = Object.values(groupedArchivedEntries).flat().find(entry => entry.id === id);
                if (entryToDelete) {
                    await deleteDoc(doc(notesCollection2, entryToDelete.id));
                } else {
                    console.error("Entry not found to delete in archive.");
                }
            } else {
                const entryToDelete = Object.values(groupedEntries).flat().find(entry => entry.id === id);
                if (entryToDelete) {
                    await deleteDoc(doc(notesCollection, entryToDelete.id));
                } else {
                    console.error("Entry not found to delete in active collection.");
                }
            }
        } catch (error) {
            console.error("Error deleting entry: ", error);
        }
    };

    

    return (
        <div className="appContainer">
            <div className="left-border" ></div>
            <div className="contentsHome2"> 
                <div>
                    <p className="contentsHome2Welcome">Welcome, {user?.displayName + " ♡"|| "Guest"}</p>
                </div> 
                {/*<EntryForm addEntry={addEntry2} /> */}

                <p className="contentsHome2p1">My Gratitude Notes</p>
                {Object.entries(groupedEntries).map(([month, entries]) => (
                    <div key={month}>
                        <p className="month_tracker">{month}</p>
                        <EntryList
                            entries={entries}
                            onArchive={archiveEntry} 
                            onDelete={deleteEntry}
                            archiveMode={false} 
                        />
                    </div>
                ))}
                <CharityDashboard />
                <div className="ArchiveToggleSet">
                    <p className="contentsHome2p2">Archived Notes</p>
                    <button onClick={toggleArchivedNotes} className="ArchiveToggle">
                        {showArchived ? 'Hide' : 'Show'} Archived Notes
                    </button>
                </div>

                 {showArchived && (
                <div>
                    {Object.entries(groupedArchivedEntries).map(([month, entries]) => (
                        <div key={month}>
                            <p className="month_tracker">{month}</p>
                            <EntryList
                                entries={entries}
                                onArchive={restoreEntry} 
                                onDelete={deleteEntry} 
                                archiveMode={true} 
                            />
                        </div>
                    ))}
                    
                </div>
            )}
                {/* Render the CharityDashboard component */}
                {/*<CharityDashboard />*/}
                {/*<EntryForm addEntry={addEntry2}/>*/}
                <button onClick ={handleSignOut} className="LogOutButton">Logout</button>

            </div>
        </div>
    );
}

const styles = {
    /*
    appContainer: {
        margin: '0 auto',
        marginLeft: '3.5vw',
        marginRight: '3.5vw',
  
        fontFamily: 'Newsreader, sans-serif',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '100px',
    },
    leftBorder: {
        width: '3.5vw',
        height: '100%',
    },
    contentsHome2: {
        marginLeft: '3.5vw', 
        marginRight: '1.75vw',        
       
        margin: '0 auto',
        flex: 1,
        paddingTop: '70px',
        fontFamily: 'Newsreader',
        fontWeight: 400,
    
    },

    contentsHome2Welcome: {
        fontSize: '1.8vw',


    },
   
    contentsHome2p1: {
        fontSize: '2.8rem',
        marginBottom: '0px',
        position: 'relative',

    },

    contentsHome2p2: {
        fontSize: '2.8rem',
        marginBottom: '0px',
        position: 'relative',

    },
   
    month_tracker: {
        fontSize: '2rem',
        color:'#655656',

    },

    ArchiveToggleSet: {
        position: 'relative',
        
      

    },
    ArchiveToggle: {
        backgroundColor: '#F5F0E3',
        left: '0rem',
        top: '3rem',
        borderRadius: '5px',
        //border: 'solid 1px',
        border: 'transparent',
        padding: '8px',
        cursor: 'pointer',
        color: '#655656',
    },
    LogOutButton: {
        backgroundColor: '#F5F0E3',
        position: 'absolute',
 
        borderRadius: '5px',
        //border: 'solid 1px',
        border: 'transparent',
        padding: '8px',
        cursor: 'pointer',
        marginTop:'60px',
        color: '#655656',


    }
        */
    
};

export default Journal;
