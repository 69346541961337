// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, setDoc, getDocs } from "firebase/firestore"
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional



const firebaseConfig = {
  apiKey: "AIzaSyBUvEVPeojBVl8lljMvScOxjuarj4EAuko",
  authDomain: "journaling-11c9f.firebaseapp.com",
  projectId: "journaling-11c9f",
  storageBucket: "journaling-11c9f.firebasestorage.app",
  messagingSenderId: "362264855858",
  appId: "1:362264855858:web:15d6329ed242bc1c88832b",
  measurementId: "G-95QNBX70KS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)
export const notesCollection = collection(db, "journalEntries")
export const notesCollection2 = collection(db, "journalArchivedEntries")
export const charityCollection = collection(db, "charityDashboard")
export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
export {googleProvider};
export default app;


