export default {
    "success": true,
    "data": {
        "memes": [
            {
                "id": "181913649",
                "name": "charity : water",
                "info": "charity: water is a nonprofit organization bringing clean and safe water to people around the world. They work with local experts and community members to find the best sustainable solution in each place where we work. Their water projects are locally-led and community-owned.",
                "url": "/charitywaterpump.jpg",
                "donate": "https://www.charitywater.org/the-spring/direct?utm_source=adwords&utm_medium=paid-ppc&utm_campaign=spring-acq-usrow&utm_term=cBC_CW_G_PAID_SEARCH_BRAND_ALL_US+ROW_OBSV_ALL_PHRASE_ALL&utm_content=branded&gad_source=1&gclid=Cj0KCQiArby5BhCDARIsAIJvjIQkcPJqEEo0VBg755_EIldpghIH7SJYDlimGoxx5WoJby63d6v51FwaAhIiEALw_wcB", 
                "width": 1200,
                "height": 1200,
                "learnMore": "https://www.charitywater.org/",
                "box_count": 2
            },
            {
                "id": "87743020",
                "name": "friendship",
                "info":"Friendship develops scalable solutions to strengthen marginalised communities, and empower people. Friendship began its operations in 2002 pioneering a hospital ship for the most unaddressed and deprived communities in the world living on sandbar islands, called chars, of the Jamuna River (Brahmaputra) in Bangladesh.",
                "url": "/friendshipboat.jpg",
                "donate": "https://friendship.ngo/donate/", 
                "width": 600,
                "height": 908,
                "learnMore": "https://friendship.ngo/",
                "box_count": 3
            },
            {
                "id": "112126428",
                "name": "thrive",
                "info": "Thrive began as a tiny expat start-up; They saw hungry children and knew they had to help, so they asked the schools in the urban slums what they needed most. The resounding response was food. The moms replied with a promise to deliver bananas to 250 schoolchildren once a week. With the overwhelmingly positive community response Thrive received, they expanded their meals and the schools they serve.",
                "url": "/thrivefood.jpg",
                "donate": "https://www.thrive-global.org/", 
                "width": 1200,
                "height": 800,
                "learnMore": "https://www.thrive-global.org/",
                "box_count": 3
            },
            {
                "id": "131087935",
                "name": "jaago",
                "info": "JAAGO Foundation envisions a society free from all forms of exploitation and discrimination, where every child has the opportunity for education, and every youth has the opportunity to realise their potential.",
                "url": "/jaago.jpg",
                "donate":  "https://jaago.com.bd/donate", 
                "width": 761,
                "height": 1024,
                "learnMore": "https://jaago.com.bd/",
                "box_count": 5
            },
            {
                "id": "247375501",
                "name": "abinta kabir",
                "info": "The Abinta Kabir Foundation was formed on 5th March, 2017 to make unrealised dreams of a young philanthropist, a high spirited beautiful soul and an intelligent academic whose head full of brilliant ideas.",
                "url": "/abintaschool.jpg",
                "donate":  "https://www.abintafoundation.org/donation/", 
                "width": 937,
                "height": 720,
                "learnMore": "https://www.abintafoundation.org/",
                "box_count": 4
            },
            {
                "id": "129242436",
                "name": "lef for life",
                "info": "LEF for Life empowers underprivileged children in Dhaka’s slums through love, education and food. We provide a safe and nurturing environment where children receive the support they need. In our school and orphanage accommodation children are provided with Love, Education and Food.",
                "url": "/lefschool.jpg",
                "donate":  "https://lefforlife.org/contact",
                "width": 482,
                "height": 361,
                "learnMore": "https://lefforlife.org/",
                "box_count": 2
            }
        ]
    }
}