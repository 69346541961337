import React, {useState} from 'react';

function EntryList({ entries, onArchive, onDelete, archiveMode }) {
  const [hoveredEntry, setHoveredEntry] = useState(null);
  const handleMouseEnter = (entryId) => setHoveredEntry(entryId);
  const handleMouseLeave = () => setHoveredEntry(null);


  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000); // Convert Firebase Timestamp to JS Date
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(date);
  };

  return (
     /*
    <div>
    {entries.map(entry => (
        <div key={entry.id}>
            <p>{entry.text}</p>
            <button onClick={() => onArchive(entry.id)}>{archiveMode ? 'Restore' : 'Archive'}</button>
            <button onClick={() => onDelete(entry.id, archiveMode)}>Delete</button>
        </div>
    ))}
    </div>
    */
    ///*
    <div className="entryBigContainer">
      {entries.map(entry => (
        <div key={entry.id} className="entryContainer" onMouseEnter={() => handleMouseEnter(entry.id)}
        onMouseLeave={handleMouseLeave}>

          <div className="entryComponents">
            <p className="entryText">{entry.text}</p>
            {/*<button style= {styles.RestoreArchiveButton(hoveredEntry === entry.id)} onClick={() => onArchive(entry.id, archiveMode)}>*/}
            <button 
              className={`RestoreArchiveButton ${hoveredEntry === entry.id ? 'hovered' : ''}`} 
              onClick={() => onArchive(entry.id, archiveMode)}
            >
              {archiveMode ? <i className="material-icons">unarchive</i> : <i className="material-icons">archive</i>}
            </button>
            {/*<button style={styles.deleteButton(hoveredEntry === entry.id)} onClick={() => onDelete(entry.id, archiveMode)}>*/}
            <button 
              className={`deleteButton ${hoveredEntry === entry.id ? 'hovered' : ''}`} 
              onClick={() => onDelete(entry.id, archiveMode)}
            >
              <span className="material-icons">delete</span>
            </button>
          </div>
          {/*<p style={styles.entryDate}>Date: {formatDate(entry.createdAt)}</p>*/}
          {/*<p style={styles.entryDate(hoveredEntry === entry.id)}>{formatDate(entry.createdAt)}</p>*/}
          <p className={`entryDate ${hoveredEntry === entry.id ? 'hovered' : ''}`}>{formatDate(entry.createdAt)}</p>



        </div>
      ))}
    </div> 
    //*/
    


  );
}
/*

const styles = {
  entryBigContainer: {
    marginTop:'-20px',

  },
  entryContainer: {
    //border: '1px solid #655656',
    paddingTop: '1px',
    paddingBottom: '13px',
    paddingLeft:'18px',
    paddingRight:'18px',
    marginBottom: '10px',
    borderRadius: '5px',
    fontSize: '1rem',
    fontFamily: 'sans-serif',
    backgroundColor: '#F5F0E3',
    position: 'relative',
    transition: 'all 0.3s ease',

  },
  entryComponents: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    //backgroundColor: 'red',
    top: '0px',
  },
  entryText: {
    fontSize: '16px',
    color: '#655656',
    
  },
  entryDate: (isHovered) => ({
    fontSize: '12px',
    color: '#888',
    position: 'absolute',
    bottom: '0px',
    opacity: isHovered ? 1 : 0, // Show on hover
    transition: 'opacity 0.3s ease',
  }),

  deleteButton: (isHovered) => ({
    backgroundColor: 'transparent',
    color: '#655656',
    borderRadius: '4px',
    border:'transparent',
    padding: '5px',
    cursor: 'pointer',
    position: 'absolute',
    right: '16px',
    display: 'block',
    top:'10px',
    fontSize:'1rem',
    width: '1rem',
    height: '1rem',
    justifyContent: 'center',
    opacity: isHovered ? 1 : 0, // Show on hover
    transition: 'opacity 0.3s ease',
    
  }),

  RestoreArchiveButton: (isHovered) => ({
    
    // marginLeft: '200px',
    // backgroundColor: 'red',
    // color: 'white',
    // border: 'none',
    // padding: '5px',
    // cursor: 'pointer',
    // position: 'absolute',
    // right: '20px',
    // top:'15px',
    
    color: '#655656',
    backgroundColor: 'transparent',
    //border: '1px solid #ddd',
    border:'none',
    borderRadius: '4px',
    //padding: '8px 12px',
    padding: '5px',
    width: '1rem',
    height: '1rem',
    position: 'absolute',
    fontSize: '1rem',
    right: '45px',
    top:'10px',
    cursor: 'pointer',
    opacity: isHovered ? 1 : 0, // Show on hover
    transition: 'opacity 0.3s ease',

  }),
  
};

*/
export default EntryList;
