import React, { useState, useEffect } from 'react';
//import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
//import { auth, googleProvider } from './firebase.js';
import {GoogleButton} from 'react-google-button';
import {UserAuth} from './AuthContext';
import {useNavigate} from 'react-router-dom'

const SignIn = () => {

  //const [email, setEmail] = useState('');
  //const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const{googleSignIn, user} = UserAuth();
  const navigate = useNavigate()


/*
  // Handle email and password sign-in
  const handleSignInWithEmail = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirect or do something after successful sign-in
    } catch (err) {
      setError(err.message);
    }
  };
*/
  // Handle Google sign-in
  /*const handleSignInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      // Redirect or do something after successful sign-in
    } catch (err) {
      setError(err.message);
    }
  };
  */

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      //navigate('/myjournal')
      // Redirect or do something after successful sign-in
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    if (user !=null){
      navigate('/myjournal');
    }
  }, [user]);

  return ( 
    <div className="signContainer">
     {/*<p style={styles.Title}>Account</p>*/}
     <div className="miniContainer">
        {error && <p>{error}</p>}
        {/*<form onSubmit={handleSignInWithEmail}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Sign In with Email</button>
        </form>*/}
        {/*<button onClick={handleSignInWithGoogle}>Sign In with Google</button>*/}
        <p className="Title">Welcome</p>
        <p className="TitleText">Sign in to continue to monerkotha </p>


        <div>
          <GoogleButton onClick={handleGoogleSignIn} style={styles.GoogleButton} className="GoogleButton"/>
        </div>
      </div>
    </div>
  );
};


const styles={
  /*
  signContainer: {
        margin: '0 auto',
        fontFamily: 'Newsreader, sans-serif',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "#FFFCF4",
        height:'900px',


  },
  miniContainer: {
    backgroundColor: "#FAF6EB",
    maxWidth: '500px',
    marginLeft:'10px',
    marginRight:'10px',
    height:'250px',
    margin: 'auto',
    flex: 1,
    paddingBottom:'130px',
    fontFamily: 'Newsreader',
    fontWeight: 400,
    textAlign: 'center',
    justifyContent:'center',
    position:'relative',
    display: 'flex', // Enable flexbox
    flexDirection: 'column', // Ensure content stacks vertically
    alignItems: 'center', // Center children horizontally
    borderRadius:'9px',
    boxShadow:'2px 2px 2px#E1DDD8',
    border: '.5px solid #E1DDD8',
    marginTop: '200px',

  },
  Title: {
    justifyText: 'center',
    fontSize: '2.5rem',
    fontFamily:'sans-serif',
    marginBottom:'-2px'
  },
  TitleText: {
    justifyText: 'center',
    fontSize: '1rem',
    fontFamily:'sans-serif',
    marginBottom:'30px',
  },
  */
  GoogleButton: {
    backgroundColor: '#FCF8EF',
    color:'black',
    borderRadius:'5px',
    fontSize:'13px',
    boxShadow:'none',
    border: '.5px solid #E1DDD8',

    '@media (max-width: 375px)': {
      fontSize: 'px', // Smaller font size for small screens
      padding: '5px 10px', // Adjust padding
    },


  },

};

export default SignIn;
