export default {
    "success": true,
    "data": {
        "socialOrganisations": [
            {
                "id": "181913649",
                "name": "charity : water",
                "info": "“Charity water’s enterprise gives 100% of the  profits from sales help fund clean water. They sell merchandise and a book by the CEO on his journey to nonprofit work, and work with partners who give a share of their profits to help fund clean water.",
                //"url": "/charitystore.jpg",
                "donate": "https://store.charitywater.org/", 
                "width": 1200,
                "height": 1200,
                "learnMore": "https://www.charitywater.org/",
                "box_count": 2
            },
            {
                "id": "87743020",
                "name": "friendship colors of the chars",
                "info": "Friendship Colors of the Chars is an enterprise of Friendship NGO. They use natural dyes to make sarees, ornas, scarves and more. Their products are made by women of the chars, remote parts of Bangladesh.",
                "url": "/friendshipStore.jpg",
                "donate": "https://www.friendshipenterprise.com/", 
                "width": 600,
                "height": 908,
                "learnMore": "https://friendship.ngo/",
                "box_count": 3
            },
            {
                "id": "129242436",
                "name": "lef for life",
                "info": "Buy One, Feed One.Buy one; nourish a life, feed one. LEF for life sells jute bags, crochet dolls, greeting cards, silk scarfs, date palm products, organic soaps, quits and jewelry.",
                "url": "/lefStore.jpg",
                "donate":  "https://lefforlife.org/public/category/product/9",
                "width": 482,
                "height": 361,
                "learnMore": "https://lefforlife.org/",
                "box_count": 2
            }
        ]
    }
}